import { colors } from './colors'
import { breakpoints, mediaBreakpoints } from './breakpoints'
import { zindex } from './z-index'
import { fonts } from './fonts'

export const globals = { 
  colors, 
  breakpoints,
  mediaBreakpoints,
  zindex,
  fonts
}
