import thumbnail from "./assets/claudia-luchetti.png";

const reviews = [
  {
    name: "Claudia Luchetti",
    profession: "Coach y Decodificadora",
    thumbnail,
    video:
      "https://storage.googleapis.com/storage-klouser-web-app-prod/static-assets/review_claudia_luchetti_compressed.mp4",
    review:
      '"...Es una plataforma en la que puedo entregar mis contenidos, armar mis talleres, workshops. Puedo llegar a diario con una propuesta transformadora. En mi comunidad ya se sumaron unas 200 personas y configuramos una suerte de Tribu de pertenencia…"',
    background: "",
  },
];

export default reviews;
