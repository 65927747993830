import slugify from "slugify";
import { Redirect } from "react-router-dom";
import constants from "./constants";

import Header from "./components/Header";
import Banner from "./components/Banner";
import Section from "./components/Section";
import Whatsapp from "./components/Whatsapp";
import { GlobalStyles } from "./styles";
import { getUrlParamByName } from "./helpers/get-url-param";

const sources = [
  {
    src: "ig",
    utm_source: "Instagram",
    utm_medium: "Message",
    utm_campaign: "StorytellerIG",
  },
  {
    src: "crm",
    utm_source: "CRM",
    utm_medium: "Message",
    utm_campaign: "StorytellerCRM",
  },
];

const getSourceUtms = (src) => {
  return sources.filter((source) => source.src === src)[0];
};

const getSource = () => {
  return getUrlParamByName("src");
};

const getCurrentUrl = () => {
  return window.location.host;
};

const getRedirectData = () => {
  const currentUrl = getCurrentUrl();
  const source = getSource();
  const { src, ...rest } = getSourceUtms(source);
  let urlUtms = "";

  for (const utm in rest) {
    if (urlUtms !== "") {
      urlUtms += "&";
    }
    urlUtms += utm + "=" + encodeURIComponent(rest[utm]);
  }

  return { search: urlUtms, state: { status: 301 } };
};

const App = () => {
  const comesFromSource = getSource();

  if (!!comesFromSource) {
    return <Redirect to={{ ...getRedirectData() }} />;
  }

  return (
    <div className="App">
      <GlobalStyles />
      <Header />
      <Banner />
      {constants.sections.map((section, index) => {
        return (
          <Section
            section={section}
            firstSection={index === 0}
            key={`section-${slugify(String(Math.random() * 100), {
              lower: true,
            })}`}
            index={index}
            totalSections={constants.sections.length}
          />
        );
      })}
      <Whatsapp floating />
    </div>
  );
};

export default App;
