import styled from "styled-components";

export const Header = styled("header")`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 60px;
`;

export const Logo = styled("img")<{ src }>`
  max-width: 150px;
`;
