import whatsappIcon from "../assets/images/icons/whatsapp.svg";

const whatsapp = {
  enabled: true,
  number: 541173622069,
  message: "Hola! recomendaron mi perfíl y queria conocer mas sobre Klouser",
  url: "https://api.whatsapp.com/send",
  icon: whatsappIcon,
};

export default whatsapp;
