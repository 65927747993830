import styled from "styled-components";

export const WhatsApp = styled("a")<{ floating; marginBottom? }>`
  height: 36px;
  background: rgba(111, 207, 151, 1);
  box-shadow: 0px 8px 24px rgba(38, 50, 56, 0.1),
    0px 16px 32px rgba(38, 50, 56, 0.08);
  text-shadow: 0px 2px 4px rgba(38, 50, 56, 0.16),
    0px 4px 8px rgba(38, 50, 56, 0.08);
  letter-spacing: 0.25px;
  font-size: 16px;
  line-height: 19px;
  text-decoration: none;
  color: white;
  border-radius: 16px;
  min-width: 110px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  ${(props) => `margin-bottom: ${props?.marginBottom || 20}px`};

  ${(props) =>
    props.floating
      ? `
    display: scroll;
    position: fixed;
    z-index: 1000;
    right: 16px;
    bottom: 80px;
    min-width: 110px;`
      : ``}

  @media (min-width: ${(props) => props.theme.mediaBreakpoints.smMin}) {
    ${(props) => (props.floating ? `bottom: 80px;` : `bottom: 0px;`)}
  }
`;

export const Icon = styled.img`
  margin-right: 8px;
`;
