import Grid from "../../components/Grid";
import Markdown from "markdown-to-jsx";
import { Text } from "../../components/Section/styles";

const Component = () => {
  return (
    <>
      <Grid.Row>
        <Grid.Col sm={4}>
          <Text>
            <Markdown>1.Registro **gratuito** y sin inversión previa</Markdown>
          </Text>
        </Grid.Col>

        <Grid.Col sm={4}>
          <Text>
            <Markdown>
              2. Elige lanzar una **Experiencia** ( talleres, cursos,
              masterclass ) o una **Membresía** (las personas abonan una
              suscripción mensual para acceder a tu contenido).
            </Markdown>
          </Text>
        </Grid.Col>

        <Grid.Col sm={4}>
          <Text>
            <Markdown>
              3.Listo!\n\nSólo cobraremos una **pequeña comision** cuando
              comiences a generar ingresos.
            </Markdown>
          </Text>
        </Grid.Col>
      </Grid.Row>
    </>
  );
};

export default Component;
