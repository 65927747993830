import { Col } from "./styles";
interface ComponentProps {
  offset?: {
    xs?: number;
    sm?: number;
    md?: number;
    lg?: number;
  };
  xs?: number;
  sm?: number;
  md?: number;
  lg?: number;
  children: any;
  style?: any;
  order?: number;
}

const Component = ({ children, ...rest }: ComponentProps) => {
  return <Col {...rest}>{children}</Col>;
};

export default Component;
