/**
 *  Colors
 */

export const colors = {
  Purple: "#6C5CE7",
  PurpleLight: "#CF6AFF",
  PurpleExtraLight: "#FAF3FF",
  Yellow: "#FDCB6E",
  YellowLight: "#FFEAA7",
  Pink: "#FFF7E9",
  Lila: "rgba(108, 92, 231, 0.08)",

  Black: "#2D3436",
  Black24: "#C2C2C2",
  Black32: "rgba(0,0,0,.32)",
  Black40: "rgba(45, 52, 54, 0.4)",
  Black45: "rgba(45, 52, 54, 0.45)",
  Black01: "rgba(0, 0, 0, 1)",

  Transparent25: "rgba(255, 255, 255, 0.25)",

  White: "#FFFFFF",

  Gray16: "#F2F2F2",
  Gray: "#FAF3FF",
  Gray1: "#ecf0f1",
  Gray68: "#ADADAD",
  Gray5: "#E4E4E5",
  Gray8: "#B8B7BC",
  Gray9: "#69779B",


  BlueLight: "#69779B",
  SoftBlue: "rgba(105, 119, 155, 0.15)",

  Success: "#51CF66",
  Info: "#259DD5",
  Warning: "#FAB005",
  Danger: "#FF0000",

  WaterGreen: "#12B1B4",
  SoftOrange: "#FDCB6E",
};
