import { Image, Center } from "../../styles";

const Component = ({ section }) => {
  return (
    <Center>
      <Image src={section.image} />
    </Center>
  );
};

export default Component;
