import { colors } from "./../globals/colors";

export const form = {
  skin: {
    base: {
      idle: {
        color: colors.Black,
        placeHolderColor: colors.Gray68,
        backgroundColor: colors.Gray16,
        borderColor: colors.Gray16,
        iconColor: colors.Gray68,
      },
      disabled: {
        color: colors.Black,
        placeHolderColor: colors.Black,
        backgroundColor: colors.Black24,
        borderColor: colors.Black24,
        iconColor: colors.Gray68,
      },
      active: {
        color: colors.Black,
        placeHolderColor: colors.Purple,
        backgroundColor: colors.Gray16,
        borderColor: colors.PurpleLight,
        iconColor: colors.Purple,
      },
      hover: {
        color: colors.Black,
        placeHolderColor: colors.Black,
        backgroundColor: colors.Gray16,
        borderColor: colors.PurpleLight,
        iconColor: colors.Gray68,
      },
      focus: {
        color: colors.Black,
        placeHolderColor: colors.Purple,
        backgroundColor: colors.Gray16,
        borderColor: colors.PurpleLight,
        iconColor: colors.Purple,
      },
      error: {
        color: colors.Danger,
        placeHolderColor: colors.Danger,
        backgroundColor: colors.Gray16,
        borderColor: colors.Danger,
        iconColor: colors.Danger,
      },
    },
  },
  size: {
    md: {
      fontSize: "18px",
      height: "48px",
      lineHeight: "32px",
      padding: "16px",
    },
  },
};
