import banner from "./banner";
import sections from "./sections";
import socialNetworks from "./social-networks";
import reviews from "./reviews";
import whatsapp from "./whatsapp";

const constants = {
  banner,
  sections,
  socialNetworks,
  reviews,
  whatsapp,
};

export default constants;
