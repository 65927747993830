import styled, { keyframes } from "styled-components";

const loader1 = keyframes`
  0% { transform: scale(0);}
  100% { transform: scale(1);}
`;

const loader2 = keyframes`
  0% { transform: scale(1);}
  100% { transform: scale(0);}
`;

const loader3 = keyframes`
  0% { transform: translate(0, 0);}
  100% { transform: translate(24px, 0);}
`;

export const Loader = styled.div`
  display: block;
  position: relative;
  width: 80px;
  height: 13px;
  margin: 0px auto;
`;
export const Circle = styled("div")<{ color?: string }>`
  position: absolute;
  top: 0;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: ${(props) => (props.color ? props.color : "#FFFFFF")};
  animation-timing-function: cubic-bezier(0, 1, 1, 0);

  &:nth-child(1) {
    left: 8px;
    animation: ${loader1} 0.6s infinite;
  }

  &:nth-child(2) {
    left: 8px;
    animation: ${loader3} 0.6s infinite;
  }

  &:nth-child(3) {
    left: 32px;
    animation: ${loader3} 0.6s infinite;
  }

  &:nth-child(4) {
    left: 56px;
    animation: ${loader2} 0.6s infinite;
  }
`;
