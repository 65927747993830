import styled from "styled-components";
import UImage from "../Image";

export const Image = styled(UImage)`
  @media (min-width: ${(props) => props.theme.mediaBreakpoints.smMin}) {
    width: 64px;
    height: 64px;
  }
`;

export const Controls = styled("div")`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  @media (min-width: 1024px) {
    &.controls {
      transition: opacity 0.2s ease-in-out;
      opacity: 0;
      z-index: -1;
    }
  }
`;
export const PlayButton = styled("button")`
  background-color: transparent;
  padding: 0px;
  border: 0px;
  cursor: pointer;
`;
export const PauseButton = styled("button")`
  background-color: transparent;
  padding: 0px;
  border: 0px;
  cursor: pointer;
`;
export const Video = styled("video")`
  display: block;
`;
export const ModalBackdrop = styled("div")`
  opacity: 0.5;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100%;
  height: calc(100vh * 2);
  background-color: #000;
`;

export const Modal = styled("div")`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: auto;
  pointer-events: auto;
`;

export const ModalDialog = styled("div")<{ width? }>`
  max-width: calc(100vw - 30px);

  @media (min-width: ${(props) => props.theme.mediaBreakpoints.mdMin}) {
    max-width: calc(100vw / 2);
  }
`;

export const ModalContent = styled("div")`
  position: relative;
  display: flex;
  flex-direction: column;
  left: 0;
  bottom: 0;
  right: 0;
  margin: 0;
  outline: 0;
  background-color: transparent;
  width: 100%;
  overflow: hidden;
  padding: 0;
  border-radius: 16px;

  @media (min-width: ${(props) => props.theme.mediaBreakpoints.smMin}) {
    width: auto;
    min-height: 0;
  }

  @media (min-width: 1024px) {
    &:hover {
      .header,
      .controls {
        transition: opacity 0.2s ease-in-out;
        opacity: 1;
        z-index: 1;
      }
    }
  }
`;

export const ModalHeader = styled("div")`
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  position: absolute;
  z-index: 1;
  top: 10px;
  right: 10px;

  @media (min-width: 1024px) {
    &.header {
      transition: opacity 0.2s ease-in-out;
      opacity: 0;
      z-index: -1;
    }
  }
`;

export const Close = styled("button")`
  background: transparent;
  position: absolute;
  cursor: pointer;
  top: 10px;
  right: 10px;
  padding: 0;
  margin: 0;
  border: 0;
  line-height: 1;

  &:focus {
    outline: transparent;
  }

  @media (min-width: ${(props) => props.theme.mediaBreakpoints.smMin}) {
    top: 18px;
    right: 18px;
  }
`;

export const CloseImg = styled.img`
  @media (min-width: ${(props) => props.theme.mediaBreakpoints.smMin}) {
    width: 16px;
    height: 16px;
    top: 18px;
    right: 18px;
  }
`;

export const ModalBody = styled("div")`
  position: relative;
  line-height: 1.75rem;

  @media (min-width: ${(props) => props.theme.mediaBreakpoints.smMin}) {
    flex: 1 1 auto;
  }
`;

export const ModalFooter = styled("div")`
  padding: 0 0 24px;
`;
