import { Header, Logo } from "./styles";
import logo from "../../assets/images/logo.png";

const Component = () => {
  return (
    <Header>
      <Logo src={logo} />
    </Header>
  );
};

export default Component;
