import { createBrowserHistory } from "history";
import { createStore, compose } from "redux";
import rootReducer from "./reducers";

export const history = createBrowserHistory();

const composeWithDevTools =
  ((window as any)["__REDUX_DEVTOOLS_EXTENSION_COMPOSE__"] as typeof compose) ||
  compose;

const store = (preloadedState?: any) => {
  return {
    ...createStore(rootReducer(history), preloadedState, composeWithDevTools()),
  };
};

export default store;
