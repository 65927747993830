import Grid from "../../../Grid";
import TextContent from "../TextContent";
import { Center } from "../../styles";

const Layout = ({ section }) => {
  return (
    <Grid.Col
      sm={8}
      offset={{ sm: 2 }}
      style={{
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        display: "flex",
      }}
    >
      <Center>
        <TextContent section={section} />
      </Center>
    </Grid.Col>
  );
};

export default Layout;
