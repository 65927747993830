import ReactDOM from "react-dom";
import { GlobalStyles, FooterSticky } from "./styles";

const footerSticky = document.getElementById("footer-sticky") as HTMLElement;

const Component = ({ children, fixed }) => {
  if (!fixed) {
    return (
      <>
        <GlobalStyles />
        {children}
      </>
    );
  }

  return ReactDOM.createPortal(
    <FooterSticky>
      <GlobalStyles />
      {children}
    </FooterSticky>,
    footerSticky
  );
};

export default Component;
