import { useState, useEffect, useRef } from "react";
import ReactDOM from "react-dom";
import { Transition } from "react-transition-group";
import { Animation, AnimationFixed } from "../Animation";
import { connect, useDispatch } from "react-redux";
import { hideModal } from "../../store/actions/modal";

import play from "../../assets/images/player/play.svg";
import pause from "../../assets/images/player/pause.svg";
import closeIcon from "../../assets/images/close-white.svg";

import {
  ModalBackdrop,
  Modal,
  ModalDialog,
  ModalContent,
  ModalHeader,
  ModalBody,
  Close,
  Controls,
  PlayButton,
  Video,
  Image,
  CloseImg,
} from "./styles";
import { useWindowSize } from "../../hooks/useWindowSize";

export interface ModalProps {
  animation?: string;
  video: any;
  name: string;
  onClose?: () => void;
  visibleModal?: string | null;
}

export const Component = ({
  animation = "fadeIn",
  video,
  name,
  onClose,
  visibleModal,
}: ModalProps) => {
  const { isMobile } = useWindowSize();
  const modalDOM = document.getElementById("modal") as HTMLElement;
  const dispatch = useDispatch();
  const [isVisible, setIsVisible] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const videoRef = useRef<any>();

  const handleClose = () => {
    dispatch(hideModal());
    if (onClose) {
      onClose();
    }
  };

  const setBodyStyles = () => {
    if (isVisible) {
      document.body.style.height = "100%";
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.height = "auto";
      document.body.style.overflow = "auto";
    }
  };

  const handlePlay = () => {
    if (!isPlaying) {
      setIsPlaying(true);
      videoRef.current.play();
    } else {
      setIsPlaying(false);
      videoRef.current.pause();
    }
  };

  useEffect(() => {
    setIsVisible(name === visibleModal);
  }, [name, visibleModal]);

  useEffect(() => {
    setBodyStyles();
  }, [isVisible]);

  if (!isVisible) {
    return null;
  }

  return ReactDOM.createPortal(
    <Transition in={isVisible} timeout={350} mountOnEnter>
      {(state) => (
        <>
          <AnimationFixed name={animation} className={`animation-${state}`}>
            <Modal>
              <ModalDialog>
                <ModalContent>
                  <ModalHeader className="header">
                    <Close onClick={() => handleClose()}>
                      <CloseImg
                        src={closeIcon}
                        alt="X"
                        width="10px"
                        height="10px"
                      />
                    </Close>
                  </ModalHeader>

                  <ModalBody>
                    <Video
                      ref={videoRef}
                      width="100%"
                      {...(isMobile && { controls: true })}
                    >
                      <source
                        src={video}
                        type="video/mp4"
                        style={{ objectFit: "cover" }}
                      />
                      Tu navegador no soporta Video.
                    </Video>
                  </ModalBody>

                  {!isMobile && (
                    <Controls className="controls">
                      <PlayButton onClick={handlePlay}>
                        {!isPlaying ? (
                          <Image
                            src={play}
                            alt="Play"
                            width="35px"
                            height="35px"
                          />
                        ) : (
                          <Image
                            src={pause}
                            alt="Pause"
                            width="35px"
                            height="35px"
                          />
                        )}
                      </PlayButton>
                    </Controls>
                  )}
                </ModalContent>
              </ModalDialog>
            </Modal>
          </AnimationFixed>

          <Animation name="fadeIn" className={`animation-${state}`}>
            <ModalBackdrop onClick={() => handleClose()} />
          </Animation>
        </>
      )}
    </Transition>,
    modalDOM
  );
};

const state = ({ modalStore }) => ({ ...modalStore });

export default connect(state)(Component);
