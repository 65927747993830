import styled from 'styled-components'

export const Row = styled.div`
  ${props => {
    const { gutter } = props.theme.grid

    return `
      display: flex;
      flex-wrap: wrap;
      margin-right: -${gutter / 2}px;
      margin-left: -${gutter / 2}px;`
  }}
`