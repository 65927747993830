import styled from "styled-components";
import { Link as ReactRouterLink } from "react-router-dom";

const linkStyles = {
  outline: {
    purple: {
      color: "Purple",
      borderColor: "Purple",
      backgroundColor: "Transparent25",
    },
    transparent: {
      color: "White",
      borderColor: "Transparent25",
      backgroundColor: "Transparent25",
    },
  },
  filled: {
    purple: {
      color: "White",
      borderColor: "Purple",
      backgroundColor: "Purple",
    },
    transparent: {
      color: "White",
      borderColor: "Transparent25",
      backgroundColor: "Transparent25",
    },
  },
  link: {
    purple: {
      color: "Purple",
      borderColor: "White",
      backgroundColor: "White",
    },
    transparent: {
      color: "Purple",
      borderColor: "Transparent25",
      backgroundColor: "transparent",
    },
  },
};

export const Link = styled("a")<{ type; size; skin; block?: boolean }>`
  ${(props: any) => {
    const { block, size, type, skin, theme } = props;

    return `
      ${block ? `display: block; width: 100%;` : `display: inline-block;`}
      ${
        skin !== "transparent"
          ? `border: 2px solid ${
              theme.colors[linkStyles[type][skin].borderColor]
            };`
          : ``
      }
      background-color: ${theme.colors[linkStyles[type][skin].backgroundColor]};
      font-size: ${theme.link.size[size].fontSize};
      color: ${theme.colors[linkStyles[type][skin].color]};
      border-radius: ${theme.link.size[size].borderRadius};
      ${type !== "link" ? `padding: 0px 12px;` : ``};
      ${type !== "link" ? `min-width: 120px;` : ``};
      ${
        type !== "link"
          ? `line-height: ${theme.link.size[size].lineHeight};`
          : ``
      }
      text-decoration: none;
      letter-spacing: 0.25px;
      font-weight: 700;
      text-align: center;
    `;
  }}
`;

export const NativeLink = styled(ReactRouterLink)<{
  type;
  size;
  skin;
  block?: boolean;
}>`
  ${(props: any) => {
    const { block, size, type, skin, theme } = props;

    return `
      ${block ? `display: block; width: 100%;` : `display: inline-block;`}
      ${
        skin !== "transparent"
          ? `border: 2px solid ${
              theme.colors[linkStyles[type][skin].borderColor]
            };`
          : ``
      }
      background-color: ${theme.colors[linkStyles[type][skin].backgroundColor]};
      font-size: ${theme.link.size[size].fontSize};
      color: ${theme.colors[linkStyles[type][skin].color]};
      border-radius: ${theme.link.size[size].borderRadius};
      ${type !== "link" ? `padding: 0px 12px;` : ``}
      ${type !== "link" ? `min-width: 120px;` : ``}
      ${
        type !== "link"
          ? `line-height: ${theme.link.size[size].lineHeight};`
          : ``
      }
      text-decoration: none;
      letter-spacing: 0.25px;
      font-weight: 700;
      text-align: center;
    `;
  }}
`;

export const DisabledLink = styled("span")<{
  type;
  size;
  skin;
  block?: boolean;
}>`
  ${(props: any) => {
    const { block, size, type, skin, theme } = props;

    return `
      ${block ? `display: block; width: 100%;` : `display: inline-block;`}
      ${
        skin !== "transparent"
          ? `border: 2px solid ${
              theme.colors[linkStyles[type][skin].borderColor]
            };`
          : ``
      }
      background-color: ${theme.colors[linkStyles[type][skin].backgroundColor]};
      font-size: ${theme.link.size[size].fontSize};
      color: ${theme.colors[linkStyles[type][skin].color]};
      border-radius: ${theme.link.size[size].borderRadius};
      ${type !== "link" ? `padding: 0px 12px;` : ``};
      ${type !== "link" ? `min-width: 120px;` : ``};
      ${
        type !== "link"
          ? `line-height: ${theme.link.size[size].lineHeight};`
          : ``
      }
      text-decoration: none;
      letter-spacing: 0.25px;
      font-weight: 700;
      text-align: center;
    `;
  }}
`;
