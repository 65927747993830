import { Container } from "./styles";

interface propsInterface {
  gutter?: number;
  children: any;
  style?;
}

const Component = ({ children, gutter, ...rest }: propsInterface) => {
  return (
    <Container gutter={gutter} {...rest}>
      {children}
    </Container>
  );
};

export default Component;
