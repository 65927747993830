import styled from "styled-components";
import LinkUi from "../../components/Link";

export const TextContainer = styled("div")`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  max-width: 600px;
  margin: 0 auto;
`;

export const Title = styled("h3")`
  margin-top: 0px;
  margin-bottom: 36px;
  font-weight: 700;
  text-align: center;

  font-size: 28px;
  line-height: 32px;

  @media (min-width: 768px) {
    font-size: 32px;
    line-height: 36px;
    text-align: left;
  }

  @media (min-width: 1024px) {
    font-size: 36px;
    line-height: 40px;
  }

  * {
    margin: 0px;
  }
`;

export const Text = styled("p")<{
  marginBottom?;
  fontSize?;
  lineHeight?;
  textAlign?;
}>`
  font-size: ${({ fontSize }) => (!!fontSize ? fontSize : 16)}px;
  line-height: ${({ lineHeight }) => (!!lineHeight ? lineHeight : 28)}px;
  text-align: ${({ textAlign }) => (!!textAlign ? textAlign : "center")};
  margin: 0px;
  ${({ marginBottom }) =>
    !!marginBottom ? "margin-bottom:" + marginBottom + "px;" : ""}

  div {
    display: inline;
    @media (min-width: 768px) {
      display: block;
    }
    & > div {
      @media (min-width: 768px) {
        display: inline;
      }
    }
  }
`;

export const Calculator = styled("div")`
  border: 2px solid #000000;
  border-radius: 16px;
  padding: 25px 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  max-width: 600px;
  margin: 50px auto;

  & > div {
    width: 100%;
  }

  @media (min-width: 768px) {
    padding: 50px 30px;
  }

  /* The slider itself */
  .slider {
    -webkit-appearance: none; /* Override default CSS styles */
    appearance: none;
    width: 100%; /* Full-width */
    height: 2px; /* Specified height */
    background: #d8d8d8; /* Grey background */
    outline: none; /* Remove outline */
    opacity: 1; /* Set transparency (for mouse-over effects on hover) */
    -webkit-transition: 0.2s; /* 0.2 seconds transition on hover */
    transition: opacity 0.2s;
    margin: 0px;
  }

  /* Mouse-over effects */
  .slider:hover {
    opacity: 1; /* Fully shown on mouse-over */
  }

  /* The slider handle (use -webkit- (Chrome, Opera, Safari, Edge) and -moz- (Firefox) to override default look) */
  .slider::-webkit-slider-thumb {
    -webkit-appearance: none; /* Override default look */
    appearance: none;
    background: white;
    border-radius: 4px;
    cursor: pointer;
    width: 18px;
    height: 43px;
    touch-action: none;
    border: 2px solid #d8d8d8;
  }

  .slider::-moz-range-thumb {
    background: white;
    border-radius: 4px;
    cursor: pointer;
    width: 18px;
    height: 43px;
    touch-action: none;
    border: 2px solid #d8d8d8;
  }
`;

export const Select = styled("select")`
  width: 100%;
  height: 100%;
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  overflow: hidden;
  -webkit-appearance: menulist-button;
`;

export const SelectValue = styled("span")`
  color: #39364f;
  color: var(--eds-ui-800, #39364f);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  line-height: 22px;
  padding: 4px 12px 4px 14px;
  font-size: 24px;
  line-height: 24px;
  font-weight: 700;
`;

export const SelectContainer = styled("div")`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 20px 0px 50px;
`;

export const SelectArrow = styled("span")`
  width: 100%;
  height: 100%;
  max-width: 30px;
  max-height: 30px;
`;

export const MinValue = styled("span")`
  // position: absolute;
  // left: 0px;
  width: 30px;
  text-align: right;
  margin-right: 15px;
  cursor: pointer;
`;

export const MaxValue = styled("span")`
  // position: absolute;
  // right: 0px;
  width: 30px;
  text-align: left;
  margin-left: 15px;
  cursor: pointer;
`;

export const Label = styled("label")`
  font-weight: bold;
  font-size: 16px;
  text-align: left;
  display: block;
`;

export const Range = styled("div")`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0px;
  margin: 20px 0px 50px;
`;

export const InputRange = styled("div")`
  width: 100%;
  max-width: 300px;
  display: flex;
  align-items: center;
  justify-content: centfleer;
  height: 43px;
  position: relative;

  &:before,
  &:after {
    content: "";
    display: block;
    width: 8px;
    height: 8px;
    border-radius: 100%;
    border: 2px solid #d8d8d8;
    position: absolute;
  }

  &:before {
    left: 100%;
  }

  &:after {
    right: 100%;
  }
`;

export const Center = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

export const Total = styled("p")`
  font-size: 28px;
`;

export const Link = styled(LinkUi)`
  font-size: 18px;
  line-height: 22px;
  padding: 0px 20px;
  height: 68px;
  border-radius: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  margin: 0px 10px 0px;
`;

export const Input = styled("input")<{ ref? }>``;
export const Output = styled("output")<{ ref? }>`
  width: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  width: 30px;
  height: 24px;
  line-height: 24px;
  font-size: 15px;
  display: block;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
`;
