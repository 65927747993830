import { WhatsApp, Icon } from "./styles";
import constants from "../../constants";

interface ComponentProps {
  floating?: boolean;
  options?: {
    marginBottom?: number;
  };
}

const initialValue = {
  options: {
    marginBottom: 20,
  },
};

const Component = ({ floating, options }: ComponentProps) => {
  const { whatsapp } = constants;
  const { marginBottom } = {
    ...initialValue.options,
    ...options,
  };

  return whatsapp.enabled ? (
    <WhatsApp
      marginBottom={marginBottom}
      floating={floating}
      href={`${constants.whatsapp.url}?phone=${whatsapp.number}&text=${constants.whatsapp.message}`}
      target="_blank"
    >
      <Icon src={whatsapp.icon} alt="Whatsapp" width="16px" height="16px" />
      Ayuda
    </WhatsApp>
  ) : null;
};

export default Component;
