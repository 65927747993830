import { useEffect, useRef, useReducer } from 'react'

const SET_IS_VISIBLE = 'SET_IS_VISIBLE'
const SET_OPTIONS = 'SET_OPTIONS'

interface Options {
  root?: HTMLInputElement | null
  rootMargin?: string
  threshold?: number
}

interface State {
  options: Options | null
  isVisible: boolean
  wasVisible: boolean
}

const initialState: State = {
  options: {
    root: null,
    rootMargin: '0px',
    threshold: 1.0,
  },
  isVisible: false,
  wasVisible: false,
}

interface SetIsVisible {
  type: typeof SET_IS_VISIBLE
  payload: boolean
}

interface SetOptions {
  type: typeof SET_OPTIONS
  payload: Options
}

type Actions = SetIsVisible | SetOptions

const Reducer = (state: State, action: Actions): State => {
  switch (action.type) {
    case SET_IS_VISIBLE:
      return {
        ...state,
        isVisible: action.payload || state.wasVisible,
        wasVisible: true,
      }
    case SET_OPTIONS:
      return {
        ...state,
        options: action.payload,
      }
    default:
      return state
  }
}

export const useLazyLoad = (options?: Options) => {
  const [state, dispatch] = useReducer(Reducer, initialState)
  const containerRef = useRef<Element>(null)

  const callback = entries => {
    const [entry] = entries
    dispatch({ type: SET_IS_VISIBLE, payload: entry.isIntersecting })
  }

  useEffect(() => {
    if (options) {
      dispatch({ type: SET_OPTIONS, payload: options })
    }
  }, [options])

  useEffect(() => {
    const observer = new IntersectionObserver(callback, options)

    if (containerRef.current) {
      observer.observe(containerRef.current)
    }

    return () => {
      observer.disconnect()
    }
  }, [containerRef, options, state.options])

  return { ...state, containerRef }
}
