const size = {
  sm: {
    fontSize: "14px",
    lineHeight: "30px",
    borderRadius: "17px",
  },
  md: {
    fontSize: "14px",
    lineHeight: "32px",
    borderRadius: "18px",
  },
  lg: {
    fontSize: "16px",
    lineHeight: "44px",
    borderRadius: "24px",
  },
  xl: {
    fontSize: "24px",
    lineHeight: "52px",
    borderRadius: "16px",
  },
};

export const link = {
  size,
};
