import styled, { keyframes } from "styled-components";
import LinkUi from "../../../Link";

const scrollDown = keyframes`
  0% {
    top: 0%;
    opacity: 0;
  }
  30% {
    opacity: 1;
  }
  60% {
    opacity: 1;
  }
  100% {
    top: 90%;
    opacity: 0;
  }`;

export const Link = styled(LinkUi)<{ styles }>`
  font-size: 18px;
  line-height: 22px;
  padding: 0px 20px;
  height: 68px;
  border-radius: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  max-width: 100%;
  margin: 0px 10px 15px;
  ${({ styles }) =>
    styles.marginBottom !== undefined
      ? `margin-bottom:${styles.marginBottom}`
      : "0px"}
`;

export const Arrow = styled("button")`
  border: 0px;
  width: 24px;
  height: 40px;
  background-color: #6c5ce7;
  border-radius: 12px;
  position: relative;
  overflow: hidden;
  cursor: pointer;

  &:before,
  &:after {
    content: "";
    position: absolute;
    top: 20%;
    left: 50%;
    height: 10px;
    width: 10px;
    transform: translate(-50%, -100%) rotate(45deg);
    border: 2px solid white;
    border-top: transparent;
    border-left: transparent;
  }

  &:after {
    top: 45%;
  }

  &:before {
    top: 65%;
    animation-delay: 0.3s;
  }

  &:hover {
    &:before,
    &:after {
      animation: ${scrollDown} 1s ease-in-out infinite;
    }

    &:after {
      top: 45%;
    }

    &:before {
      top: 65%;
      animation-delay: 0.3s;
    }
  }
`;

export const Ctas = styled("div")`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
`;

export const CtaWrapper = styled("div")<{ fixed: boolean }>`
  ${({ fixed }) => (!!fixed ? "position: fixed; width: 100%; bottom: 0px" : "")}
`;
