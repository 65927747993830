import banner from "../assets/images/banner/field.jpg";
import { scrollTo } from "../helpers/scroll-to";

interface BannerDto {
  title?: {
    text: string;
    position: "center" | "left" | "right";
  };
  cta?: {
    type: "link" | "button";
    label: string;
    action?: () => void;
    href?: string;
  };
  text?: string;
  background?: {
    color?: string;
    image?: string;
  };
}

const object: BannerDto = {
  title: {
    text: `¡Si estás aquí es porque\n\nrecomendaron tu perfil!`,
    position: "center",
  },
  cta: {
    type: "button",
    label: "Saber más",
    action: () => {
      console.log("click");
      scrollTo({ id: "section-one" });
    },
    href: "next-section",
  },
  background: {
    image: banner,
  },
};

export default object;
