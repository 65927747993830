import { useEffect, useRef, useState } from "react";
import Grid from "../../components/Grid";

import {
  Text,
  Calculator,
  MinValue,
  MaxValue,
  Range,
  Label,
  InputRange,
  Center,
  Select,
  SelectValue,
  SelectContainer,
  SelectArrow,
  Link,
  Input,
  Output,
  TextContainer,
} from "./styles";

const constants = {
  fee: 10,
  Membresia: {
    period: "por mes.",
    priceLabel: "Precio de suscripción mensual:",
    priceHelperText: "Es el precio que abonaran tus suscriptores por mes.",
    participanLabel: "Número de suscriptores:",
  },
  Experiencia: {
    period: "en un único pago.",
    priceLabel: "Precio de tu evento único:",
    priceHelperText:
      "Es el precio que abonaran tus suscriptores por única vez.",
    participanLabel: "Número de participantes:",
  },
  price: {
    min: 5,
    max: 100,
    id: "price-range",
  },
  participants: {
    min: 10,
    max: 1000,
    id: "participants-range",
  },
};

const formatCurrency = (number) => {
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    maximumSignificantDigits: 2,
  });

  return formatter.format(number);
};

const setBubble = (extremes, value, bubble, formatedValue) => {
  const newValue = Number(
    ((value - extremes.min) * 100) / (extremes.max - extremes.min)
  );
  const newPosition = 10 - newValue * 0.2;
  bubble.innerHTML = `<span>${formatedValue || value}</span>`;
  bubble.style.position = "absolute";
  bubble.style.left = `calc(${newValue}% + ${newPosition}px)`;
  bubble.style.top = "100%";
};

const Component = () => {
  const priceRef = useRef<HTMLInputElement>();
  const priceBubbleRef = useRef<HTMLInputElement>();
  const participantsRef = useRef<HTMLInputElement>();
  const participantsBubbleRef = useRef<HTMLInputElement>();
  const [price, setPrice] = useState<number>(5);
  const [participants, setParticipants] = useState<number>(10);
  const [eventType, setEventType] = useState("Experiencia");
  const [total, setTotal] = useState(0);

  useEffect(() => {
    if (!!priceRef.current && !!priceBubbleRef.current) {
      priceRef.current.addEventListener("input", () => {
        setBubble(
          { min: constants.price.min, max: constants.price.max },
          priceRef.current?.value,
          priceBubbleRef.current,
          formatCurrency(priceRef.current?.value)
        );
      });
    }

    if (!!participantsRef.current && !!participantsBubbleRef.current) {
      participantsRef.current.addEventListener("input", () => {
        setBubble(
          { min: constants.participants.min, max: constants.participants.max },
          participantsRef.current?.value,
          participantsBubbleRef.current,
          null
        );
      });
    }

    setTotal(price * participants);
  }, [price, participants]);

  return (
    <>
      <Grid.Row>
        <Grid.Col>
          <TextContainer>
            <Text textAlign="left">1. Elige el tipo de evento.</Text>
            <Text textAlign="left">2. Define un precio</Text>
            <Text textAlign="left">3. Simula una cantidad de suscriptores</Text>
          </TextContainer>
        </Grid.Col>
      </Grid.Row>

      <Grid.Row>
        <Grid.Col>
          <Calculator>
            <Grid.Row>
              <Grid.Col>
                <Label htmlFor="eventType">
                  ¿Qué tipo de evento quieres publicar?
                </Label>
                <p style={{ marginTop: "0px", textAlign: "left" }}>
                  Membresía o Experiencia (curso, taller, masterclass,
                  challenge)
                </p>
                <span>
                  <SelectContainer>
                    <SelectValue>{eventType}</SelectValue>
                    <SelectArrow>
                      <svg
                        id="chevron-down-chunky_svg__eds-icon--chevron-down-chunky_svg"
                        x="0"
                        y="0"
                        viewBox="0 0 24 24"
                      >
                        <path
                          id="chevron-down-chunky_svg__eds-icon--chevron-down-chunky_base"
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M7 10.2l5 5 5-5-1.4-1.4-3.6 3.6-3.6-3.6z"
                        ></path>
                      </svg>
                    </SelectArrow>
                    <Select
                      id="eventType"
                      onChange={(event) => setEventType(event.target.value)}
                    >
                      <option value="Experiencia" data-spec="select-option">
                        Experiencia
                      </option>
                      <option value="Membresia" data-spec="select-option">
                        Membresía
                      </option>
                    </Select>
                  </SelectContainer>
                </span>
              </Grid.Col>
            </Grid.Row>

            <Grid.Row>
              <Grid.Col>
                <Label htmlFor={constants.price.id}>
                  {constants[eventType].priceLabel}
                </Label>
                <p style={{ marginTop: "0px", textAlign: "left" }}>
                  {constants[eventType].priceHelperText}
                </p>
                <Range>
                  <MinValue
                    onClick={() => setPrice(Number(constants.price.min))}
                  >
                    {formatCurrency(constants.price.min)}
                  </MinValue>
                  <InputRange>
                    <Input
                      ref={priceRef}
                      id={constants.price.id}
                      name={constants.price.id}
                      type="range"
                      min={constants.price.min}
                      max={constants.price.max}
                      className="slider"
                      value={price}
                      onChange={(event) => setPrice(Number(event.target.value))}
                    />
                    <Output ref={priceBubbleRef} className="priceBubble" />
                  </InputRange>
                  <MaxValue
                    onClick={() => setPrice(Number(constants.price.max))}
                  >
                    {formatCurrency(constants.price.max)}
                  </MaxValue>
                </Range>
              </Grid.Col>
            </Grid.Row>

            <Grid.Row>
              <Grid.Col>
                <Label htmlFor={constants.participants.id}>
                  {constants[eventType].participanLabel}
                </Label>
                <Range>
                  <MinValue
                    onClick={() =>
                      setParticipants(Number(constants.participants.min))
                    }
                  >
                    {constants.participants.min}
                  </MinValue>
                  <InputRange>
                    <Input
                      ref={participantsRef}
                      id={constants.participants.id}
                      name={constants.participants.id}
                      type="range"
                      min={constants.participants.min}
                      max={constants.participants.max}
                      className="slider"
                      value={participants}
                      onChange={(event) =>
                        setParticipants(Number(event.target.value))
                      }
                    />
                    <Output
                      ref={participantsBubbleRef}
                      className="participantBubble"
                    />
                  </InputRange>
                  <MaxValue
                    onClick={() =>
                      setParticipants(Number(constants.participants.max))
                    }
                  >
                    {constants.participants.max}
                  </MaxValue>
                </Range>
              </Grid.Col>
            </Grid.Row>
            <Grid.Row>
              <Grid.Col>
                <p style={{ margin: 0, textAlign: "left" }}>
                  <small>
                    El ejemplo de la calculadora de precios está en US$. Monedas
                    disponibles en nuestra plataforma: AR$, US$ y EUR.{" "}
                  </small>
                </p>
              </Grid.Col>
            </Grid.Row>
          </Calculator>

          <Grid.Row>
            <Grid.Col>
              <Text marginBottom={35} fontSize={24} lineHeight={36}>
                <div>
                  Publicando una {eventType} puedes ganar hasta{" "}
                  <strong>
                    {formatCurrency(total - (total / 100) * constants.fee)}
                  </strong>{" "}
                  <strong>{constants[eventType].period}</strong>
                </div>
                <div>
                  Cobraremos una comisión del {constants.fee}% sólo cuando
                  comiences a generar ingresos.
                </div>
              </Text>
            </Grid.Col>
          </Grid.Row>

          {/* <Grid.Row>
            <Grid.Col>
              <Center>
                <Link
                  styles={{ marginTop: "30px" }}
                  target="_blank"
                  options={{
                    type: "filled",
                    size: "lg",
                    skin: "purple",
                    native: false,
                  }}
                  href="https://klouser.app/expert/signup"
                >
                  Regístrate gratis hoy
                </Link>
              </Center>
            </Grid.Col>
          </Grid.Row> */}
        </Grid.Col>
      </Grid.Row>
    </>
  );
};

export default Component;
