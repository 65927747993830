import constants from "../../constants";
import VideoPlayer from "../../components/VideoPlayer";
import {
  Title,
  Thumbnail,
  ImageVideo,
  ImagePlay,
  PlayButton,
  Center,
  ReviewTitle,
  Profession,
} from "./styles";
import play from "../../assets/images/player/play.svg";
import { useDispatch } from "react-redux";
import { showModal } from "../../store/actions/modal";
import Grid from "../../components/Grid";

const Component = () => {
  const dispatch = useDispatch();
  const { name, profession, thumbnail, video } = constants.reviews[0];

  return (
    <Grid.Row>
      <Grid.Col>
        <Title>
          Alguien como tú ya utiliza nuestra plataforma. Escucha su testimonio
        </Title>
        <Center>
          <Thumbnail>
            <ImageVideo
              src={thumbnail}
              alt="Thumbnail"
              width="auto"
              height="auto"
              lazy={false}
            />
            <PlayButton
              onClick={() => dispatch(showModal("reivew-video-player"))}
            >
              <ImagePlay src={play} alt="Play" width="35px" height="35px" />
            </PlayButton>
          </Thumbnail>
          <ReviewTitle>{name}</ReviewTitle>
          <Profession>{profession}</Profession>
        </Center>
        <VideoPlayer name="reivew-video-player" video={video} />
      </Grid.Col>
    </Grid.Row>
  );
};

export default Component;
