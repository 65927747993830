import { useWindowSize } from "../../../../hooks/useWindowSize";
import Grid from "../../../Grid";
import Image from "../ImageContent";
import Text from "../TextContent";

const Layout = ({ section }) => {
  const { isMobile, isTabletPortrait } = useWindowSize();

  return (
    <>
      <Grid.Col
        offset={{ sm: 1, md: 2 }}
        sm={10}
        md={section.inverted ? 3 : 5}
        style={{
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          display: "flex",
        }}
      >
        {!isMobile &&
          !isTabletPortrait &&
          (section.inverted ? (
            <Image section={section} />
          ) : (
            <Text section={section} />
          ))}

        {(isMobile || isTabletPortrait) && <Image section={section} />}
      </Grid.Col>

      <Grid.Col
        offset={{ sm: 1, md: 0 }}
        sm={10}
        md={section.inverted ? 5 : 3}
        style={{
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          display: "flex",
        }}
      >
        {!isMobile &&
          !isTabletPortrait &&
          (section.inverted ? (
            <Text section={section} />
          ) : (
            <Image section={section} />
          ))}

        {(isMobile || isTabletPortrait) && <Text section={section} />}
      </Grid.Col>
    </>
  );
};

export default Layout;
