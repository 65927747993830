import styled from "styled-components";

export const Banner = styled("div")`
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  min-height: 570px;

  @media (min-width: 768px) {
    min-height: 660px;
  }
`;

export const BannerContent = styled("div")`
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  text-align: center;

  @media (min-width: 768px) {
    width: 190px;
    top: 120px;
    left: 120px;
    transform: translate(0, 0);
    width: auto;
  }

  * {
    margin: 0px;
  }
`;

export const Background = styled("img")<{ src }>`
  position: absolute;
  left: 50%;
  top: 35%;
  transform: translate(-50%, -50%);
`;

export const Title = styled("h1")`
  font-size: 42px;
  line-height: 46px;
  font-weight: 700;
  text-align: center;
  color: #6c5ce7;
  margin: 0px 0px 15px;

  @media (min-width: 768px) {
    font-size: 36px;
    line-height: 36px;
  }

  * {
    margin: 0px;
  }
`;

export const Text = styled("p")``;
