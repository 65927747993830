import { createGlobalStyle } from "styled-components";

export const GlobalStyles = createGlobalStyle`
  #footer-sticky {
    position: sticky;
    bottom: 0;
    z-index: 100;
  }

  #root {
    position: relative;
  }

  #header-sticky {
    position: sticky;
    top: 0;
    z-index: 100;
  }

  #modal {
    position: relative;
    z-index: 110;
  }
`;
