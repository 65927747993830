import Markdown from "markdown-to-jsx";
import Button from "../../../Button";
import FixedFooter from "../../../FixedFooter";
import { Link, Arrow, Ctas } from "./styles";
import { Text, FormatedText, Center } from "../../styles";
import { useWindowSize } from "../../../../hooks/useWindowSize";
import { getUrlParamByName } from "../../../../helpers/get-url-param";

const Component = ({ section }) => {
  const { isMobile } = useWindowSize();
  return (
    <>
      {!!section.markdown && (
        <FormatedText>
          <Markdown>{section.markdown}</Markdown>
        </FormatedText>
      )}

      {!!section.text && (
        <Text>
          <Markdown>{section.text}</Markdown>
        </Text>
      )}

      <Ctas>
        {section?.cta?.map((cta) => {
          if (cta.action && cta.type === "button") {
            return (
              <FixedFooter fixed={cta.fixed && isMobile}>
                <Button
                  options={cta.options}
                  styles={{ ...cta.styles, marginBottom: "0px" }}
                  onClick={cta.action}
                >
                  {cta.label}
                </Button>
              </FixedFooter>
            );
          }

          if (cta.href && cta.type === "link") {
            return (
              <FixedFooter fixed={cta.fixed && isMobile}>
                <Link
                  target="_blank"
                  options={{ ...cta.options, native: false }}
                  styles={{ ...cta.styles, marginBottom: "0px" }}
                  href={`${cta.href}?lead_id=${getUrlParamByName("lead_id")}`}
                >
                  {cta.label}
                </Link>
              </FixedFooter>
            );
          }

          return null;
        })}
      </Ctas>

      {section.showArrow && (
        <Center style={{ marginTop: "20px", height: "40px" }}>
          <Arrow />
        </Center>
      )}
    </>
  );
};

export default Component;
