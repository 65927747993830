import Grid from "../../../Grid";
import Markdown from "markdown-to-jsx";
import { Text, FormatedText, Center } from "../../styles";

const Layout = ({ section }) => {
  return (
    <Grid.Col sm={10} md={10} offset={{ sm: 1, md: 1 }}>
      {!!section.markdown && (
        <FormatedText>
          <Markdown>{section.markdown}</Markdown>
        </FormatedText>
      )}

      {!!section.text && (
        <Text>
          <Markdown>{section.text}</Markdown>
        </Text>
      )}

      <Center>{section.component}</Center>
    </Grid.Col>
  );
};

export default Layout;
