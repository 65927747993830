import { useEffect } from "react";
import { useLazyLoad } from "../../hooks/useLazyLoad";
import { Image } from "./styles";

interface ComponentProps {
  src: string;
  width: string;
  height: string;
  alt: string;
  className?: string;
  lazy?: boolean;
  preload?: boolean;
}

const Component = ({
  src,
  width,
  height,
  alt,
  preload,
  ...rest
}: ComponentProps) => {
  const { containerRef, isVisible } = useLazyLoad();

  useEffect(() => {
    if (preload) {
      const head = document.querySelector("head");
      const link = document.createElement("link");
      link.setAttribute("rel", "preload");
      link.setAttribute("as", "image");
      link.setAttribute("href", src);

      if (head) {
        head.appendChild(link);
      }
    }
  }, [preload]);

  return (
    <Image
      {...(isVisible && { src })}
      ref={containerRef}
      width={width}
      height={height}
      alt={alt}
      {...rest}
    />
  );
};

export default Component;
