import styled from "styled-components";

export const Section = styled("section")<{
  bgColor?;
  designedSalt?;
  marginTop?;
  zIndex;
}>`
  max-width: 100%;
  position: relative;
  z-index: ${({ zIndex }) => (zIndex ? zIndex : "1")};
  background-color: ${({ bgColor }) => (bgColor ? bgColor : "white")};
  padding: 90px 0px 0px;

  & + section {
    padding: ${({ designedSalt }) =>
      designedSalt ? "130px 0px 60px" : "90px 0px"};

    @media (min-width: 768px) {
      padding: ${({ designedSalt }) =>
        designedSalt ? "190px 0px 90px" : "90px 0px"};
    }
  }

  ${({ marginTop }) =>
    marginTop !== undefined ? `padding-top: ${marginTop}px!important;` : ""}
`;

export const Title = styled("h3")`
  color: #6c5ce7;
  margin-top: 0px;
  margin-bottom: 40px;
  font-weight: 700;
  text-align: center;
  width: 100%;
  font-size: 28px;
  line-height: 32px;

  @media (min-width: 768px) {
    font-size: 32px;
    line-height: 36px;
  }

  @media (min-width: 1024px) {
    font-size: 36px;
    line-height: 45px;
  }

  * {
    margin: 0px;
  }
`;

export const FormatedText = styled("div")`
  font-size: 22px;
  line-height: 28px;
  text-align: center;

  @media (min-width: 768px) {
    font-size: 26px;
    line-height: 32px;
  }

  @media (min-width: 1024px) {
    font-size: 30px;
    line-height: 36px;
  }
`;

export const Text = styled("p")`
  font-size: 22px;
  line-height: 28px;
  text-align: center;

  @media (min-width: 768px) {
    font-size: 26px;
    line-height: 32px;
  }

  @media (min-width: 1024px) {
    font-size: 30px;
    line-height: 36px;
  }
`;

export const Center = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  text-align: center;
`;

export const Wave = styled("div")<{ bgColor? }>`
  overflow: hidden;
  position: absolute;
  z-index: 1;
  top: calc(100% - 1px);
  left: 0px;
  right: 0px;
  width: 100%;
  height: 92px;
  background-color: transparent;
`;

export const FirstSectionWave = styled("div")`
  overflow: hidden;
  position: absolute;
  bottom: 100%;
  left: 0;
  z-index: 5;
  height: 100px;
  width: 100%;

  @media (min-width: 768px) {
    height: 215px;
  }

  & > svg {
    position: absolute;

    &:last-child {
      @media (min-width: 768px) {
        top: auto;
      }
    }
  }
`;

export const LeftShape = styled("div")`
  width: 150px;
  position: absolute;
  z-index: 10;
  top: -20px;
  left: 0px;

  @media (min-width: 768px) {
    top: 120px;
  }

  & > svg {
    width: 100%;
    max-width: 150px;

    @media (min-width: 768px) {
      max-width: none;
    }
  }
`;

export const RightShape = styled("div")`
  width: 150px;
  position: absolute;
  z-index: 10;
  top: 15px;
  right: 0px;

  @media (min-width: 768px) {
    top: 60px;
  }

  & > svg {
    width: 100%;
    max-width: 150px;

    @media (min-width: 768px) {
      max-width: none;
    }
  }
`;

export const Image = styled("img")<{ src }>`
  margin-bottom: 20px;
  max-width: 80%;

  @media (min-width: 768px) {
    max-width: 100%;
  }

  @media (min-width: 992px) {
    margin-bottom: 0px;
  }
`;

export const Background = styled("img")``;
