import React from "react";
import queHaceKlouser from "../assets/images/que-hace-klouser-por-ti.png";
import asesorEstrategico from "../assets/images/asesor-estrategico.png";
import transformaTuComunidad from "../assets/images/transforma-tu-comunidad.png";

import Calculator from "../sections/Calculator";
import Review from "../sections/Reviews";
import HowWorks from "../sections/HowWorks";

export interface SectionsDto {
  designedSalt?: boolean;
  showLeftShape?: boolean;
  showRightShape?: boolean;
  showArrow?: boolean;
  inverted?: boolean;
  component?: any;
  marginTop?: number;
  id: string;
  layout?: "one-column" | "two-column" | "component";
  title?: {
    text: string;
    position: "center" | "left" | "right";
  };
  text?: string;
  markdown?: string;
  image?: string;
  cta?: {
    fixed: boolean;
    options?: any;
    styles?: any;
    type: "link" | "button";
    label: string;
    action?: () => void;
    href?: string;
  }[];
  background?: {
    color?: string;
    image?: string;
  };
}

const object: SectionsDto[] = [
  {
    id: "section-one",
    designedSalt: false,
    showLeftShape: true,
    showArrow: false,
    layout: "one-column",
    title: {
      text: `Tú ayudas a las personas,\n\nKlouser te ayuda a ti`,
      position: "center",
    },
    markdown: `Klouser es una plataforma específica para referentes del mundo del bienestar. Trabajamos con personas como tú expertas en diferentes temáticas que generan cambios positivos en la vida de las personas.`,
    background: {
      color: "",
      image: "",
    },
  },
  {
    id: "section-two",
    showArrow: false,
    inverted: true,
    layout: "two-column",
    title: {
      text: "¿Qué hace Klouser por ti?",
      position: "center",
    },
    markdown: `Imagina lanzar un taller, masterclass o cursos para tu audiencia, **organizando todo desde un sólo lugar**, sin tener que preocuparte por links de pago y consultas técnicas.\n\n\n**¿Te gustaría que al finalizar un evento puedas ofrecerles ser parte de tu comunidad?**`,
    image: queHaceKlouser,
    background: {
      color: "",
      image: "",
    },
  },
  {
    id: "section-three",
    designedSalt: true,
    showArrow: false,
    inverted: false,
    layout: "one-column",
    marginTop: 0,
    title: {
      text: "¡Hacemos todo eso y mucho más! ",
      position: "center",
    },
    markdown: ``,
    background: {
      color: "",
      image: "",
    },
  },
  {
    id: "section-four",
    showArrow: false,
    designedSalt: false,
    showLeftShape: true,
    layout: "two-column",
    title: {
      text: "",
      position: "center",
    },
    markdown: `Contamos con un **“Asesor Estratégico”** que te acompañará en todo el camino para que tus eventos sean todo un éxito. Te ayudará a planificar los próximos pasos de tu negocio y estará pendiente de todos tus lanzamientos.`,
    image: asesorEstrategico,
    background: {
      color: "#E2FEEC",
      image: "",
    },
  },
  {
    id: "section-five",
    showArrow: false,
    designedSalt: false,
    showRightShape: true,
    inverted: true,
    layout: "two-column",
    title: {
      text: "",
      position: "center",
    },
    markdown: `Entendimos que generar un vínculo entre tú y tus seguidores es lo más importante para que puedas **transformar tu audiencia en una comunidad o tribu**.`,
    image: transformaTuComunidad,
    background: {
      color: "white",
      image: "",
    },
  },

  {
    id: "section-six",
    showArrow: false,
    designedSalt: true,
    layout: "component",
    title: {
      text: "¿Cómo funciona?",
      position: "center",
    },
    component: React.createElement(HowWorks, {}),
    markdown: `En Klouser, las personas reciben tu contenido para lograr una transformación a largo plazo y a la vez, tú recibes ingresos recurrentes todos los meses.`,
    background: {
      color: "#E3E6FF",
      image: "",
    },
  },
  {
    id: "section-seven",
    designedSalt: true,
    layout: "component",
    title: { text: "¿Cuánto podría ganar?", position: "center" },
    markdown: `Simula el lanzamiento de una Experiencia o Membresía con nuestra calculadora.`,
    component: React.createElement(Calculator, {}),
    background: {
      color: "white",
      image: "",
    },
  },
  {
    id: "section-height",
    designedSalt: false,
    layout: "component",
    component: React.createElement(Review, {}),
    background: {
      color: "#FFBED7",
      image: "",
    },
  },
  {
    id: "section-nine",
    designedSalt: false,
    layout: "one-column",
    title: {
      text: `¡Regístrate gratis!\n\nTenemos un lugar para tí`,
      position: "center",
    },
    text: "",
    markdown: "",
    cta: [
      {
        fixed: false,
        options: {
          type: "outline",
          size: "md",
          skin: "purple",
        },
        type: "link",
        label: "Hablar con un asesor estratégico",
        href: "https://calendly.com/alepipman/llamada-con-mi-asesor-estrategico-klouser",
      },
      // {
      //   fixed: true,
      //   options: {
      //     type: "filled",
      //     size: "md",
      //     skin: "purple",
      //   },
      //   styles: {
      //     position: "fixed",
      //     bottom: "20px",
      //     left: "20px",
      //     right: "20px",
      //     zIndex: "20",
      //   },
      //   type: "link",
      //   label: "Quiero explorar Klouser",
      //   href: "https://klouser.app/expert/signup",
      // },
    ],
    background: {
      color: "#E3E6FF",
      image: "",
    },
  },
];

export default object;
