import styled from "styled-components";

export const Container = styled("div")<{ gutter: number | undefined }>`
  ${(props) => {
    const { gutter = props.theme.grid.gutter, theme } = props;
    const { grid, breakpoints } = theme;

    let styles = `
      width: 100%;
      padding-right: ${gutter / 2}px;
      padding-left: ${gutter / 2}px;
      margin-right: auto;
      margin-left: auto;`;

    Object.keys(grid.maxWidth).forEach((breakpoint) => {
      styles += `
        @media ( min-width: ${breakpoints[breakpoint]}px ) {
          max-width: ${grid.maxWidth[breakpoint]};
        }
      `;
    });

    return styles;
  }}
`;
