import { Banner, BannerContent, Background, Title, Text } from "./styles";
import Markdown from "markdown-to-jsx";
import Button from "../Button";
import Link from "../Link";
import constants from "../../constants";

interface ComponentProps {
  children?;
}

const Component = ({ children }: ComponentProps) => {
  const { banner } = constants;
  const { background, title, cta, text } = banner;

  return (
    <Banner {...(!!background?.color && { bgColor: background.color })}>
      {!!background?.image && <Background src={background.image} />}

      <BannerContent>
        {!!title && (
          <Title>
            <Markdown>{title.text}</Markdown>
          </Title>
        )}

        {!!text && <Text>{text}</Text>}

        {cta?.action && cta?.type === "button" && (
          <Button
            options={{
              type: "filled",
              size: "lg",
              skin: "purple",
            }}
            onClick={cta?.action}
          >
            {cta?.label}
          </Button>
        )}

        {cta?.href && cta?.type === "link" && (
          <Link
            target="_blank"
            options={{
              type: "filled",
              size: "lg",
              skin: "purple",
              native: false,
            }}
            href={cta?.href}
          >
            {cta?.label}
          </Link>
        )}
      </BannerContent>

      {children}
    </Banner>
  );
};

export default Component;
