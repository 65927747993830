import Grid from "./../Grid";
import Markdown from "markdown-to-jsx";
import { Title } from "./styles";
import { SectionsDto } from "../../constants/sections";
import OneColumnLayout from "./components/OneColumnLayout";
import TwoColumnLayout from "./components/TwoColumnLayout";
import ComponentLayout from "./components/ComponentLayout";
import {
  Section,
  Wave,
  FirstSectionWave,
  LeftShape,
  RightShape,
} from "./styles";
import { useWindowSize } from "../../hooks/useWindowSize";

interface ComponentProps {
  section: SectionsDto;
  firstSection?: boolean;
  index: number;
  totalSections: number;
}

const Component = ({
  section,
  firstSection = false,
  index,
  totalSections,
}: ComponentProps) => {
  return (
    <Section
      {...(!!section.background?.color && {
        bgColor: section.background.color,
      })}
      {...(!!section.designedSalt && {
        designedSalt: section.designedSalt,
      })}
      {...(section.marginTop !== undefined && {
        marginTop: section.marginTop,
      })}
      zIndex={totalSections - index}
      id={section.id}
    >
      {firstSection && (
        <FirstSectionWave>
          <svg
            width="1440"
            height="302"
            viewBox="0 0 1440 302"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M-133.691 0L-69.5239 7C-5.35721 14 122.976 28 251.309 43C379.643 57 507.976 72 636.309 108C764.643 144 892.976 201 1021.31 180C1149.64 158 1277.98 57 1406.31 36C1534.64 14 1662.98 72 1727.14 100L1791.31 129V302H1727.14C1662.98 302 1534.64 302 1406.31 302C1277.98 302 1149.64 302 1021.31 302C892.976 302 764.643 302 636.309 302C507.976 302 379.643 302 251.309 302C122.976 302 -5.35721 302 -69.5239 302H-133.691V0Z"
              fill="#6C5CE7"
            />
          </svg>

          <svg
            width="1440"
            height="356"
            viewBox="0 0 1440 356"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M-133.395 0L-68.6251 8.78248C-3.85533 17.565 125.684 35.1299 255.196 53.6945C384.736 71.2594 514.248 89.824 643.183 129.381C772.118 168.937 900.477 229.486 1030.98 212.064C1161.51 193.643 1294.2 96.2511 1424.71 78.8292C1555.23 60.4077 1683.57 121.956 1747.76 151.731L1811.92 182.505L1807.17 355.44L1742.21 353.654C1677.25 351.869 1547.32 348.299 1417.4 344.729C1287.47 341.159 1157.55 337.588 1027.63 334.018C897.702 330.448 767.778 326.878 637.854 323.307C507.93 319.737 378.006 316.167 248.082 312.597C118.158 309.027 -11.7664 305.456 -76.7285 303.671L-141.691 301.886L-133.395 0Z"
              fill="white"
            />
          </svg>
        </FirstSectionWave>
      )}

      {section.showLeftShape && (
        <LeftShape
          {...(firstSection && {
            style: { top: "0px" },
          })}
        >
          <svg
            viewBox="0 0 206 342"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g opacity="0.4">
              <rect
                x="77.6293"
                width="128.053"
                height="102.874"
                rx="16"
                fill="#42B2FC"
              />
              <ellipse
                cx="-20.6827"
                cy="289.54"
                rx="51.6345"
                ry="51.852"
                fill="#F182AD"
              />
              <ellipse
                cx="11.1242"
                cy="53.096"
                rx="51.6345"
                ry="51.8518"
                fill="#FDCB6E"
              />
              <rect
                x="-72.3171"
                y="119.881"
                width="128.054"
                height="102.874"
                rx="16"
                fill="#12B1B4"
              />
            </g>
          </svg>
        </LeftShape>
      )}

      {section.showRightShape && (
        <RightShape>
          <svg
            viewBox="0 0 257 392"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g opacity="0.4">
              <rect
                width="146.939"
                height="118.046"
                rx="16"
                transform="matrix(-1 0 0 1 146.939 0)"
                fill="#42B2FC"
              />
              <ellipse
                rx="59.2496"
                ry="59.4992"
                transform="matrix(-1 0 0 1 259.75 332.242)"
                fill="#F182AD"
              />
              <ellipse
                rx="59.2496"
                ry="59.4991"
                transform="matrix(-1 0 0 1 223.253 60.9267)"
                fill="#FDCB6E"
              />
              <rect
                width="146.939"
                height="118.046"
                rx="16"
                transform="matrix(-1 0 0 1 319 137.562)"
                fill="#12B1B4"
              />
            </g>
          </svg>
        </RightShape>
      )}

      <Grid.Container style={{ position: "relative", zIndex: 20 }}>
        {!!section.title?.text && (
          <Grid.Row>
            <Grid.Col>
              <Title>
                <Markdown>{section.title.text}</Markdown>
              </Title>
            </Grid.Col>
          </Grid.Row>
        )}

        <Grid.Row>
          {section.layout === "one-column" && (
            <OneColumnLayout section={section} />
          )}

          {section.layout === "two-column" && (
            <TwoColumnLayout section={section} />
          )}

          {section.layout === "component" && (
            <ComponentLayout section={section} />
          )}
        </Grid.Row>
      </Grid.Container>

      {section.designedSalt && (
        <Wave>
          <svg
            width="1440"
            height="93"
            viewBox="0 0 1440 93"
            fill={section?.background?.color || "white"}
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1440 56.145C1415.83 55.9242 1382.67 60.1455 1336.07 73.3301C1197 112.678 1126.52 90.6333 933.003 21.564C739.488 -47.5053 647.799 83.87 370.329 31.4578C138.77 -12.2818 125.76 98.9129 -0.000108378 90.9766C-5.86765e-05 0.500009 2.90925e-05 90.8439 -5.91282e-05 0.500009L1440 0.500086C1440 37.8088 1440 0.500086 1440 56.145Z"
              fill={section?.background?.color || "white"}
            />
          </svg>
        </Wave>
      )}
    </Section>
  );
};

export default Component;
