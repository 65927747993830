import { ThemeProvider } from 'styled-components'
import { main } from './skins/main'
import GlobalStyles from './GlobalStyles'

const themes:any = {
  main
}

const Component = ({ portal, children }:any) => {
  return (
    <ThemeProvider theme={ themes[portal] || main }>
      <GlobalStyles />
      {children}
    </ThemeProvider>
  )
}

export default Component
