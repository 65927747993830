import facebook from "./../assets/images/social-networks/icon-facebook.svg";
import linkeding from "./../assets/images/social-networks/icon-linkedin.svg";
import slack from "./../assets/images/social-networks/icon-slack.svg";
import twitter from "./../assets/images/social-networks/icon-twitter.svg";
import instagram from "./../assets/images/social-networks/icon-instagram.svg";

const socialNetworks = [
  {
    enabled: true,
    name: "Instagram",
    url: "https://instagram.com/klouser.app",
    icon: instagram,
  },
  {
    enabled: true,
    name: "Facebook",
    url: "https://www.facebook.com/klouserapp",
    icon: facebook,
  },
  {
    enabled: false,
    name: "Linkedin",
    url: "https://www.linkedin.com/company/klousergroup/",
    icon: linkeding,
  },
  {
    enabled: false,
    name: "Slack",
    url: "",
    icon: slack,
  },
  {
    enabled: false,
    name: "Twitter",
    url: "https://twitter.com/klouser_app",
    icon: twitter,
  },
];

export default socialNetworks;
