import styled, { createGlobalStyle } from "styled-components";

export const GlobalStyles = createGlobalStyle`
  #footer-sticky {
    position: sticky;
    bottom: 0;
    z-index: 100;
  }
`;

export const FooterSticky = styled("div")`
  padding: 10px;
  background: linear-gradient(90.02deg, #6c5ce7 0.02%, #cf6aff 100.79%);

  * {
    background-color: rgba(255, 255, 255, 0.25);
    margin: 0px;
    border: 0px;
  }
`;
