import styled from "styled-components";
import UiImage from "../../components/Image";

export const Section = styled("section")<{ bgColor? }>`
  max-width: 100%;
  position: relative;
  background-color: ${({ bgColor }) => (bgColor ? bgColor : "white")};
  padding: 70px 0px !important;
`;

export const Title = styled("h3")`
  position: relative;
  z-index: 10;
  margin-top: 0px;
  margin-bottom: 36px;
  font-weight: 700;
  text-align: center;

  font-size: 28px;
  line-height: 32px;

  @media (min-width: 768px) {
    font-size: 32px;
    margin-bottom: 56px;
    line-height: 36px;
    text-align: center;
  }

  @media (min-width: 1024px) {
    font-size: 36px;
    margin-bottom: 96px;
    line-height: 40px;
  }

  * {
    margin: 0px;
  }
`;

export const Text = styled("p")`
  font-size: 16px;
  line-height: 28px;
  text-align: center;

  @media (min-width: 768px) {
    font-size: 18px;
    line-height: 30px;
    text-align: left;
  }

  @media (min-width: 1024px) {
    font-size: 24px;
    line-height: 36px;
  }
`;

export const ReviewTitle = styled.h6`
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: black;
  text-align: center;
  margin: 0px;

  @media (min-width: ${(props) => props.theme.mediaBreakpoints.smMin}) {
    font-size: 32px;
    line-height: 39px;
    margin-bottom: 12px;
  }
`;

export const Profession = styled.p`
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: black;
  text-align: center;
  margin: 0px;
  margin-bottom: 0px;

  @media (min-width: ${(props) => props.theme.mediaBreakpoints.smMin}) {
    font-size: 16px;
    line-height: 19px;
  }
`;

export const Thumbnail = styled("div")`
  position: relative;
  margin: 0px 0px 36px;
  max-width: 650px;

  &:hover {
    img {
      opacity: 1;
    }
  }

  @media (min-width: ${(props) => props.theme.mediaBreakpoints.smMin}) {
    margin: 0px 15px 56px;
  }
`;

export const ImageVideo = styled(UiImage)`
  display: block;
  position: relative;
  z-index: 2;
  border-radius: 20px;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.25);
  max-width: 100%;
  opacity: 0.9;
  transition: opacity 0.2s ease-in-out;

  @media (min-width: ${(props) => props.theme.mediaBreakpoints.smMin}) {
  }
`;

export const ImagePlay = styled(UiImage)`
  display: block;
  position: relative;
  z-index: 2;
  border-radius: 20px;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.25);

  @media (min-width: ${(props) => props.theme.mediaBreakpoints.smMin}) {
    width: 64px;
    height: 64px;
  }
`;

export const PlayButton = styled("button")`
  display: block;
  cursor: pointer;
  background-color: transparent;
  max-width: 64px;
  padding: 0px;
  height: 64px;
  border: none;
  opacity: 0.9;
  position: absolute;
  z-index: 3;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const Quote = styled("p")`
  margin: 0px;
  margin-bottom: 65px;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: white;
  text-align: center;
  max-width: 460px;
`;

export const Center = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
`;
