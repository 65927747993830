import styled from "styled-components";

const buttonStyles = {
  outline: {
    purple: {
      color: "Purple",
      borderColor: "Purple",
      backgroundColor: "Transparent25",
    },
  },
  filled: {
    purple: {
      color: "White",
      bordercolor: "Purple",
      backgroundColor: "Purple",
    },
    transparent: {
      color: "White",
      bordercolor: "Transparent25",
      backgroundColor: "Transparent25",
    },
  },
  common: {
    purple: {
      color: "Purple",
      borderColor: "White",
      backgroundColor: "White",
    },
  },
  link: {
    purple: {
      color: "Purple",
      borderColor: "White",
      backgroundColor: "White",
    },
  },
};

export const Button = styled("button")<{
  options;
  block?: boolean;
  disabled?: boolean;
}>`
  ${(props: any) => {
    const { options, theme, disabled } = props;
    const { size, type, skin, block } = options;

    return `
      cursor: pointer;
      border: none;
      display: inline-block;
      ${
        !(type !== "common" && type !== "link") || skin === "transparent"
          ? ``
          : `border: 2px solid ${
              theme.colors[buttonStyles[type][skin].borderColor]
            }`
      };
      ${
        type !== "link"
          ? `height: calc(${theme.button.size[size].lineHeight} + 4px)`
          : ``
      };
      background-color: ${
        !disabled
          ? theme.colors[buttonStyles[type][skin].backgroundColor]
          : theme.colors.Black40
      };
      font-size: ${
        type === "link" ? "inherit" : theme.button.size[size].fontSize
      };
      line-height: ${
        type === "link" ? "normal" : theme.button.size[size].lineHeight
      };
      color: ${theme.colors[buttonStyles[type][skin].color]};
      border-radius: ${theme.button.size[size].borderRadius};
      padding: ${type === "link" ? "0px" : "0px 17px"};
      text-decoration: none;
      letter-spacing: 0.25px;
      font-weight: ${type === "link" ? "normal" : "700"};
      ${block ? `width: 100%;` : ``}
      min-width: ${type === "link" ? "0" : "150px"};
      text-align: center;
    `;
  }}
`;
